import React from "react";
import { SEO, PageLayout, Background, FoodMenu } from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class MenuPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessMenuModifierSet,
      allFishermanBusinessMenuModifier,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={null}>
          <Grid
            stackable={true}
            className={"menu-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={"16"}>
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "menu_header",
                  defaultValue: "MENU",
                })}
              />
            </Grid.Column>
            <Grid.Column
              width={"16"}
              textAlign={"left"}
              className={"menu-pattern-container"}
            >
              <Background columns={1}>
                <Background.Pattern type={"CIRCLE"}>
                  <FoodMenu
                    header={""}
                    centerNavigation={true}
                    showMenuLikes={true}
                    disableScheduleAvailability={true}
                    cart={false}
                    categoryDisplayVariant={"CategoryTwoSides"}
                    itemDisplayVariant={"LeftImageHorizontalCard"}
                    itemCartModalVariant={"CatalogItemLightbox"}
                    menu={{
                      schedules: allFishermanBusinessMenuSchedule.nodes,
                      categories: allFishermanBusinessMenuCategory.nodes,
                      items: allFishermanBusinessMenuItem.nodes,
                      modifierSets: allFishermanBusinessMenuModifierSet.nodes,
                      modifiers: allFishermanBusinessMenuModifier.nodes,
                    }}
                    business={{
                      id: fishermanBusiness._id,
                      type: fishermanBusiness.type,
                    }}
                  />
                </Background.Pattern>
              </Background>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Menu" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessMenuSchedule {
      nodes {
        availableDays
        categories
        _id
        name
      }
    }
    allFishermanBusinessMenuCategory {
      nodes {
        items
        description
        name
        _id
      }
    }
    allFishermanBusinessMenuItem {
      nodes {
        modifierSets
        description
        _id
        name
        image
        imageAltText
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
        visible
        available
      }
    }
    allFishermanBusinessMenuModifierSet {
      nodes {
        _id
        minAllowed
        modifiers
        name
      }
    }
    allFishermanBusinessMenuModifier {
      nodes {
        _id
        name
        price
      }
    }
  }
`;
